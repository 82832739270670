export default [
	//前端页面
	{
		header: "主页",
	},
	{
		title: "Project List",
		icon: "MapIcon",
		route: "index-project-list",
	},
	//管理页面
	{
		header: "System Manage",
	},
	{
		title: "System Manage",
		icon: "SettingsIcon",
		children: [
			{
				title: "Account Settings",
				route: "admin-account-setting",
			},
			{
				title: "Users Manage",
				route: "admin-users-list",
			},
			{
				title: "Projects Manage",
				route: "admin-projects-list",
			},
			{
				title: "Menus Manage",
				route: "admin-menus-list",
			},
		],
	},
];
