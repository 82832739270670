<template>
  <!--	v-if="canViewVerticalNavMenuGroup(item)"-->
  <li
      :id="popover_id"
      :class="{
			open: isOpen,
			disabled: item.disabled,
			'sidebar-group-active': isActive,
		}"
      class="nav-item has-sub"
  >
    <b-link class="d-flex align-items-center" @click="() => menuAction()">
      <feather-icon :icon="item.icon || 'CircleIcon'"/>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge v-if="item.tag" :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto" pill>
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <component :is="resolveNavItemComponent(child)" v-for="(child,index) in item.children" :key="index"
                 ref="groupChild" :item="child"/>
    </b-collapse>
  </li>
</template>

<script>
import {BBadge, BCollapse, BLink, VBPopover} from "bootstrap-vue";
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from "@core/layouts/utils";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import {useUtils as useAclUtils} from "@core/libs/acl";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";

// Composition Function
import useVerticalNavMenuGroup from "./useVerticalNavMenuGroup";
import mixinVerticalNavMenuGroup from "./mixinVerticalNavMenuGroup";
import {new_nanoid} from "@/libs/utils/nanoId";
import {computed, reactive, toRefs} from "@vue/composition-api";
import {routerParams} from "@/libs/utils/routerParams";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  name: "VerticalNavMenuGroup",
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  setup(props) {
    const {isOpen, isActive, updateGroupOpen, updateIsActive} = useVerticalNavMenuGroup(props.item);

    const {t} = useI18nUtils();
    const {canViewVerticalNavMenuGroup} = useAclUtils();
    const event = reactive({
      project_id: computed(() => {
        return parseInt(routerParams("project_id"));
      }),
      popover_id: computed(() => {
        return "popover_id_" + new_nanoid();
      }),
    });
    const hoverMenuAction = () => {
      if (props.item.p_menu_id === 0) {
        menuAction();
      }
    };

    const menuAction = () => {
      const open = !isOpen.value;
      updateGroupOpen(open);
      //更新hover的一级菜单
      if (props.item.menu_id) {
        store.commit("menus/UPDATE_MENU_HOVER_LVL_1", props.item.menu_id);
      }
    };

    return {
      ...toRefs(event),
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      new_nanoid,
      hoverMenuAction,
      menuAction,
      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
    };
  },
};
</script>

<style lang="scss">
#menu_popover_lv1.popover {
  left: 24.3rem !important;
  min-width: 20rem !important;

  .popover-header,
  .popover-body {
    border: none !important;
    border-radius: 0;
  }

  ul.menu-content {
    list-style: none !important;
    padding: 0.5rem 1rem;
    margin: 0;

    li.nav-item {
      line-height: 1.8rem;
      font-size: 1rem;

      span {
        margin: 0 0.5rem;
      }
    }
  }
}
</style>
